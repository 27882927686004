<script>
import BlogDetailHeader from '~/components/blog/detail/BlogDetailHeader'

export default {
  name: 'CmsElementBsBlogDetail',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h) {
    return h(BlogDetailHeader, {
      props: {
        blog: this.content.data,
      },
    })
  },
}
</script>
