<template>
  <div>
    <div class="text-body-4 text-sm-body-3 text-md-body-2 text-right">
      {{ publishedDate }}
    </div>
    <nuxt-link
      class="
        text-overline text-uppercase
        grey-darken-4--text
        text-decoration-none
      "
      :to="categoryUrl"
    >
      {{ categoryName }}
    </nuxt-link>
    <hr
      class="grey-darken-4 mt-2 mb-4"
      style="width: 20px; border-bottom: none"
    />
    <h1 class="text-h4 text-sm-h3 text-md-h1">{{ title }}</h1>
  </div>
</template>
<script>
import { getBlogMainCategory, getCategoryUrl } from '~/helpers'

export default {
  name: 'BlogDetailHeader',
  props: {
    blog: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    title() {
      return this.blog.translated.title
    },
    mainCategory() {
      return getBlogMainCategory(this.blog)
    },
    categoryName() {
      return this.mainCategory.translated.name
    },
    categoryUrl() {
      return getCategoryUrl(this.mainCategory)
    },
    publishedDate() {
      return this.$d(new Date(this.blog.publishedAt), 'short')
    },
  },
}
</script>
